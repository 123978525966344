import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Timer, {
    renderTimer,
    renderUntilDone,
    renderWhenDone,
    renderReverseValue,
} from '../common/Timer';
import AuctionAdminControls from '../administration/AuctionAdminControls';
import WatchButton from '../auction/WatchButton';
import './PropertyCard.css';
//import AuctionStatus from './AuctionStatus';
import moment from 'moment';
import PropertyFeatures from '../auction/PropertyFeatures';
import {
    Button,
    Divider,
    Link /*, styled*/,
    useMediaQuery,
} from '@mui/material';
import auctionTypes from '../../constants/auctionTypes';
import { getNextReverse } from '../../scripts/auction';
import { getBidValueHeader } from '../../scripts/language';
import config from '../../config';
import AuctionTypeIndicator from '../auction/AuctionTypeIndicator';
import { AccountContext } from '../supertokens/Account';

function PropertyCard({ item: auction, height = 550, discrete }) {
    const slug = `/listing/${auction.slug}`;
    const hide = discrete ? 'hidden' : null;

    const highestBid = auction.bids.length > 0 ? auction.bids[0] : null;

    const xs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    /*const PropertyDetails = styled('div')(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            backgroundColor: '#f4f4f4bb',
            borderRadius: '25px',
        },
        [theme.breakpoints.up('md')]: {
            backgroundColor: '#f4f4f4bb',
            borderRadius: '25px',
        },
        [theme.breakpoints.up('lg')]: {
            backgroundColor: '#f4f4f4bb',
            borderRadius: '25px',
        },
    }));*/

    //const DETAILS_WIDTH = 380;

    return (
        <Box
            className="property-card shadow1"
            position="relative"
            sx={{ borderRadius: '25px' }}
        >
            <Link
                href={slug}
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 10,
                }}
            />
            <div className="property-card-admin-menu">
                <AuctionAdminControls auction={auction} />
            </div>
            <Box
                position="absolute"
                top={0}
                left={0}
                className={hide}
                display="flex"
                width={1}
                justifyContent="space-between"
                sx={{ padding: { xs: 2, md: 2, lg: 2 } }}
            >
                <WatchButton
                    groupId={auction.auctionHistory.auctionHistoryGroup.id}
                    className={xs ? undefined : 'watch-button-fade'}
                />
                {/* <AuctionStatus
                    startDate={auction.startDate}
                    endDate={auction.endDate}
                    sold={auction.sold}
                /> */}
            </Box>
            <Box position="relative">
                <CardMedia
                    component="img"
                    sx={{
                        height: {
                            xs: height * 0.5,
                            md: height * 0.5,
                            lg: height * 0.5,
                        },
                        borderRadius: '25px 25px 0 0',
                    }}
                    image={
                        auction.property.propertyMedia[0]?.projectMedia?.media
                            ?.url
                    }
                />
                <ViewPurchase
                    auction={auction}
                    highestBid={highestBid}
                    height={height}
                />
            </Box>
            <Box
                position="absolute"
                top={height * 0.5 - 65}
                right={0}
                zIndex={10}
                margin={1.5}
                className={xs ? undefined : 'watch-button-fade'}
                backgroundColor="#f4f4f4"
                borderRadius={100}
            >
                <AuctionTypeIndicator auctionTypeId={auction.auctionTypeId} />
            </Box>
            <Box className={`property-card-details ${hide}`}>
                <Box
                    sx={{
                        width: { xs: '100%' },
                        maxWidth: '100%',
                        padding: '25px',
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        fontSize={14}
                        letterSpacing={3}
                        component="div"
                        color="dark"
                        textTransform="uppercase"
                        fontFamily="urbanist"
                        marginBottom={0.5}
                    >
                        {`${auction.property.address.city}, ${auction.property.address.province}`}
                    </Typography>
                    <Box height={60} display="flex" justifyContent="center">
                        <Typography
                            fontSize={22}
                            component="div"
                            className="clamp2"
                            lineHeight={1.25}
                            margin="auto"
                            variant="heading"
                        >
                            {auction.title}
                        </Typography>
                    </Box>

                    <Timer
                        endDate={auction.endDate}
                        renderer={renderUntilDone}
                        accentColour="status.danger"
                        accentTime={config.auction.endingSoon}
                    >
                        <>
                            <PropertyFeatures auction={auction} />
                            <Divider
                                sx={{
                                    borderColor: '#000 !important',
                                }}
                            />
                            <ValueStatus
                                auction={auction}
                                highestBid={highestBid}
                                concluded={false}
                                sold={auction.sold}
                            />
                        </>
                    </Timer>
                    <Timer endDate={auction.endDate} renderer={renderWhenDone}>
                        <ValueStatus
                            auction={auction}
                            highestBid={highestBid}
                            concluded={true}
                            sold={auction.sold}
                        />
                    </Timer>
                </Box>
            </Box>
        </Box>
    );
}

const ValueStatus = ({ auction, concluded, highestBid, sold }) => {
    const Sold = () => {
        return (
            <div className="property-card-concluded">
                {highestBid && (
                    <Typography
                        letterSpacing={2}
                        fontFamily="urbanist"
                        fontWeight="bold"
                        paddingBottom={1}
                        fontSize={15}
                    >
                        {`Sold For: $${(+highestBid.amount).toLocaleString()}`}
                    </Typography>
                )}
                <Typography
                    letterSpacing={2}
                    fontFamily="urbanist"
                    paddingBottom={1}
                    fontSize={15}
                >
                    {`Sold On: ${moment(auction.endDate).format(
                        `MMM D, YYYY`
                    )}`}
                </Typography>
                {highestBid && (
                    <Typography
                        letterSpacing={1}
                        paddingBottom={1}
                        fontSize={15}
                    >
                        {`Purchased by: @${highestBid.user.username}`}
                    </Typography>
                )}
            </div>
        );
    };

    const NotSold = () => {
        return (
            <div className="property-card-concluded">
                {highestBid && (
                    <Typography
                        letterSpacing={2}
                        fontFamily="urbanist"
                        fontWeight="bold"
                        paddingBottom={1}
                        fontSize={15}
                    >
                        {`Highest Bid: $${(+highestBid.amount).toLocaleString()}`}
                    </Typography>
                )}
                <Typography
                    letterSpacing={2}
                    fontFamily="urbanist"
                    paddingBottom={1}
                    fontSize={15}
                >
                    {`End Date: ${moment(auction.endDate).format(
                        `MMM D, YYYY`
                    )}`}
                </Typography>
                {highestBid && (
                    <Typography
                        letterSpacing={2}
                        fontFamily="urbanist"
                        paddingBottom={1}
                        fontSize={15}
                    >
                        {`Highest Bidder: @${highestBid.user.username}`}
                    </Typography>
                )}
            </div>
        );
    };

    const NoBids = () => {
        return (
            <div className="property-card-concluded">
                <Typography
                    letterSpacing={2}
                    fontFamily="urbanist"
                    fontWeight="bold"
                    paddingBottom={1}
                    fontSize={15}
                >
                    {`Minimum Bid: $${(+auction.minimumBid).toLocaleString()}`}
                </Typography>
                <Typography
                    letterSpacing={2}
                    fontFamily="urbanist"
                    paddingBottom={1}
                    fontSize={15}
                >
                    {`End Date: ${moment(auction.endDate).format(
                        `MMM D, YYYY`
                    )}`}
                </Typography>
            </div>
        );
    };

    const Started = ({ highestBid }) => {
        const PADDING = 25;

        return (
            <Box className="property-card-horizontal-list" marginTop={2}>
                <li
                    style={{
                        paddingLeft: PADDING,
                        paddingRight: PADDING,
                        textAlign: 'right',
                    }}
                >
                    <Typography
                        letterSpacing={2}
                        fontFamily="urbanist"
                        fontSize={15}
                    >
                        {getBidValueHeader(auction, false)}
                    </Typography>

                    <AuctionValue />
                </li>
                <li
                    style={{
                        paddingLeft: PADDING,
                        paddingRight: PADDING,
                        textAlign: 'left',
                    }}
                >
                    <Typography
                        letterSpacing={2}
                        fontFamily="urbanist"
                        fontSize={15}
                    >
                        Time Remaining
                    </Typography>

                    <Typography
                        letterSpacing={2}
                        fontFamily="urbanist"
                        fontWeight="bold"
                        marginTop={0.2}
                        fontSize={15}
                    >
                        <Timer
                            endDate={auction.endDate}
                            renderer={renderTimer}
                        />
                    </Typography>
                </li>
            </Box>
        );
    };

    const Unstarted = () => {
        return (
            <Box className="property-card-horizontal-list" marginTop={2}>
                <li>
                    <Typography letterSpacing={2} fontFamily="urbanist">
                        Launching In
                    </Typography>

                    <Typography
                        letterSpacing={2}
                        fontFamily="urbanist"
                        fontWeight="bold"
                        marginTop={0.2}
                    >
                        <Timer
                            endDate={auction.startDate}
                            renderer={renderTimer}
                        />
                    </Typography>
                </li>
            </Box>
        );
    };

    const AuctionValue = () => {
        const [next, setNext] = useState(getNextReverse(auction));
        const [amount, setAmount] = useState(
            auction.startingBid - next.ticks * auction.bidIncrement
        );
        const handleComplete = () => {
            const _next = getNextReverse(auction);
            if (!_next.endDate.isAfter(moment(auction.endDate))) {
                setNext(_next);
                setAmount(
                    auction.startingBid - _next.ticks * auction.bidIncrement
                );
            }
        };

        switch (auction.auctionTypeId) {
            case auctionTypes.REVERSE.id:
                return (
                    <Timer
                        endDate={next.endDate}
                        renderer={renderReverseValue}
                        auction={auction}
                        amount={amount}
                        handleComplete={handleComplete}
                        overtime={true}
                        rate={auction.timeIncrement}
                    />
                );
            default:
                return (
                    <Typography
                        letterSpacing={2}
                        fontFamily="urbanist"
                        fontWeight="bold"
                        marginTop={0.2}
                        fontSize={15}
                    >
                        $
                        {(highestBid
                            ? +highestBid.amount
                            : +auction.minimumBid
                        ).toLocaleString()}
                    </Typography>
                );
        }
    };

    const started = moment() >= moment(auction.startDate);

    if (!started) {
        return <Unstarted />;
    } else {
        if (concluded) {
            return sold ? <Sold /> : highestBid ? <NotSold /> : <NoBids />;
        } else {
            return <Started highestBid={highestBid} />;
        }
    }
};

function ViewPurchase({ auction, highestBid, height }) {
    const account = useContext(AccountContext);

    // Exit if unsold
    if (!auction.sold || highestBid.user.username !== account.user.username) {
        return null;
    }

    return (
        <Box
            sx={{
                height: {
                    xs: height * 0.5,
                    md: height * 0.5,
                    lg: height * 0.5,
                },
                borderRadius: '25px 25px 0 0',
            }}
            position="absolute"
            width="100%"
            top={0}
            backgroundColor="#22222255"
            display="flex"
        >
            <Button
                variant="blue"
                sx={{ margin: 'auto', zIndex: 11 }}
                component={Link}
                href={`/purchase/${auction.slug}`}
            >
                View Purchase Tasks
            </Button>
        </Box>
    );
}

export default PropertyCard;
