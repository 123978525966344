import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import NotFound from '../../pages/NotFound.js';
import moment from 'moment';

import './AuctionForm.css';
import { toast } from 'react-toastify';
import { getBrokers, getDocupilotTemplates, useYup } from '../../scripts/cms';
import AuctionFormReference from './AuctionFormReference';

import { useSearchParams } from 'react-router-dom';
import CMSForm from './CMSForm.js';
import auctionTypes, { auctionTypeList } from '../../constants/auctionTypes.js';
import { Box, Button, Typography } from '@mui/material';
import Field from './Field.js';
import SelectionTable from './SelectionTable.js';
import { EventList } from '../../pages/administration/Project.js';

function AuctionForm() {
    const { slug } = useParams();
    const [valid, setValid] = useState(true);

    const [data, setData] = useState();

    const [searchParams] = useSearchParams();
    const relisting = searchParams.get('relist') === 'true';
    const referenceSlug = searchParams.get('slug');

    const [addresses, setAddresses] = useState([]);

    const [depositSchedules, setDepositSchedules] = useState([]);
    const [listingBrokers, setListingBrokers] = useState([]);
    const [commissions, setCommissions] = useState([]);
    const [models, setModels] = useState([]);
    const [incentives, setIncentives] = useState([]);

    const [restricted, setRestricted] = useState(false);

    const [propertyEvents, setPropertyEvents] = useState([]);

    useEffect(() => {
        // Get addresses
        axios
            .get(`/address/getAll`)
            .then((res) => {
                setAddresses(res.data);
            })
            .catch(function () {});

        // Get existing auction data (if you are modifying one)
        if (slug) {
            axios
                .get(`/auction/getAdmin/${slug}`)
                .then((res) => {
                    updateTemplate(res.data);
                })
                .catch(function () {
                    setValid(false);
                });

            axios
                .get(`/event/getPropertyEvents/${slug}`)
                .then((res) => {
                    setPropertyEvents(res.data);
                })
                .catch(function () {
                    setValid(false);
                });
        } else {
            if (referenceSlug) {
                axios
                    .get(`/auction/getAdmin/${referenceSlug}`)
                    .then((res) => {
                        updateTemplate(res.data, true);
                    })
                    .catch(function () {
                        setValid(false);
                    });
            }
        }
    }, [slug]);

    function updateTemplate(auction, strip) {
        const { property } = auction;

        // Handle upgrade options
        const upgrades = {};
        for (let i = 0; i < property.upgrades.length; i++) {
            const upgrade = property.upgrades[i];
            const upgradeOptions = [];
            for (let j = 0; j < upgrade.upgradeOptions.length; j++) {
                const upgradeOption = upgrade.upgradeOptions[j];
                upgradeOptions.push(upgradeOption.id);
            }
            upgrades[upgrade.id] = upgradeOptions;
        }

        // Incentives
        const incentives = [];
        for (let j = 0; j < auction.auctionIncentives.length; j++) {
            incentives.push(auction.auctionIncentives[j].incentiveId);
        }

        // Copy existing child IDs
        let existingIds = {
            addressId: property.addressId,
            projectId: property.address.projectId,
            modelId: property.modelId,
        };

        // Restricted
        setRestricted(
            moment().isAfter(moment(auction.startDate)) &&
                auction.visible === true &&
                !referenceSlug &&
                !relisting
        );

        // Strip IDs from the incoming values
        if (strip) {
            auction.id = undefined;
            auction.propertyId = undefined;
            property.id = undefined;
        }

        // Only copy the auction history group id if relisting an existing auction, or just updating
        // Why: Relisted auctions should be created in the same chain, and when modifying a relisted auction,
        //      need to ensure the property can pass validation after duplicating the unit #
        if (relisting || !referenceSlug) {
            existingIds.auctionHistoryGroupId =
                auction.auctionHistory.auctionHistoryGroup.id;
        }

        // Update fields
        setData({
            ...property,
            ...auction,
            upgrades: upgrades,
            incentives: incentives,
            ...existingIds,
        });
    }

    const inputs = [
        [
            {
                id: 'addressId',
                children: addresses,
                label: 'Select Address',
                table: {
                    columns: [
                        {
                            field: 'id',
                            headerName: 'ID',
                            flex: 0.25,
                        },
                        {
                            field: 'project',
                            headerName: 'Project',
                            flex: 1.25,
                            renderCell: (params) => {
                                return params.row.project.title;
                            },
                        },
                        {
                            field: 'title',
                            headerName: 'Title',
                            flex: 0.75,
                        },
                        {
                            field: 'country',
                            headerName: 'Country',
                            flex: 0.75,
                        },
                        {
                            field: 'province',
                            headerName: 'Province',
                            flex: 0.75,
                        },
                        {
                            field: 'city',
                            headerName: 'City',
                            flex: 0.75,
                        },
                        {
                            field: 'postalCode',
                            headerName: 'Postal Code',
                            flex: 0.75,
                        },
                        {
                            field: 'streetAddress',
                            headerName: 'Address',
                            flex: 1.25,
                        },
                    ],
                },
                restricted: true,
            },
        ],
        [
            {
                id: 'modelId',
                children: models,
                label: 'Select Model',
                table: {
                    columns: [
                        {
                            field: 'title',
                            headerName: 'Title',
                        },
                        {
                            field: 'identifier',
                            headerName: 'Identifier',
                        },
                    ],
                },
                restricted: true,
            },
        ],
        [
            {
                id: 'docupilotTemplateId',
                label: 'Docupilot Template',
                source: getDocupilotTemplates,
                sourceParameters: [
                    { id: 'projectId' },
                    { id: 'docupilotTemplateTypeId', value: 1 },
                ],
                table: {
                    columns: [
                        {
                            field: 'title',
                            headerName: 'Title',
                        },
                        {
                            field: 'templateId',
                            headerName: 'Template ID',
                        },
                    ],
                },
            },
        ],
        [
            {
                id: 'depositScheduleId',
                children: depositSchedules,
                label: 'Select Deposit Schedule',
                table: {
                    column: 'title',
                },
            },
        ],
        [
            {
                id: 'brokerId',
                source: getBrokers,
                label: 'Exclusive Broker Listing',
                sourceAttribute: 'user.username',
                //optional: true,
                //placeholder: '---',
                restricted: restricted,
            },
        ],
        [
            {
                id: 'listingBrokerId',
                children: listingBrokers,
                label: 'Select Listing Broker',
                table: {
                    columns: [
                        {
                            field: 'firstName',
                            headerName: 'First Name',
                        },
                        {
                            field: 'lastName',
                            headerName: 'Last Name',
                        },
                        {
                            field: 'email',
                            headerName: 'Email',
                        },
                    ],
                    nullable: true,
                },
                optional: true,
            },
        ],
        [
            {
                id: 'commissionId',
                children: commissions,
                label: 'Select Commission Structure',
                table: {
                    column: 'title',
                },
                restricted: restricted,
            },
        ],
        [
            {
                id: 'propertyMedia',
                optional: true,
                media: {},
            },
        ],
        [
            { id: 'title', label: 'Auction/Listing Title' },
            {
                id: 'slug',
                description: `URL for the auction page. Ex. 'auction-1' = '/listing/auction-1'`,
            },
        ],
        [{ id: 'auctionTypeId', children: auctionTypeList, restricted: true }],
        [
            {
                Component: AuctionTypeConfig,
                props: { restricted: restricted },
            },
        ],
        [
            {
                Component: InputUpgrades,
            },
        ],
        [
            {
                id: 'incentives',
                children: incentives,
                label: 'Add Incentives',
                table: {
                    multi: true,
                    columns: [
                        {
                            field: 'templateName',
                            headerName: 'Template',
                            flex: 2,
                        },
                        {
                            field: 'title',
                            headerName: 'Title',
                            flex: 2,
                        },
                        {
                            field: 'scheduleIdentifier',
                            headerName: 'Schedule Identifier',
                        },
                    ],
                },
                optional: true,
            },
        ],
        [
            {
                id: 'unit',
                optional: true,
                placeholder: 'ex. Unit 605',
                description:
                    "Unit string that will affect the display value of the unit. Ex. 'Suite 203' instead of just '203'",
                label: 'Unit Name (Listing)',
            },
            { id: 'unitNumber', optional: true, placeholder: '605' },
            { id: 'floorNumber', optional: true, number: true },
            {
                id: 'exteriorSquareFeet',
                number: true,
                description:
                    'Overrides the model-level exterior square footage',
            },
        ],
        [
            {
                id: 'occupancyDate',
                optional: true,
                placeholder: 'ex. Spring 2024, May 2025',
            },
        ],
        [
            {
                id: 'highlights',
                inputs: [
                    [
                        {
                            id: 'title',
                            placeholder: 'Highlight',
                            hideLabel: true,
                            optional: true,
                        },
                    ],
                ],
                title: 'Highlight',
                slim: true,
                optional: true,
            },
        ],
        [
            {
                id: 'occupancyFee',
                paragraph: true,
                optional: true,
                label: 'Occupancy Fee (Hidden)',
            },
        ],
        [{ id: 'description', paragraph: true, label: 'Unit Description' }],
        ...(propertyEvents.length > 0
            ? [
                  [
                      {
                          Component: EventList,
                          props: {
                              list: propertyEvents,
                              type: 'Property',
                              simple: true,
                          },
                      },
                  ],
              ]
            : []),
    ];

    const yup = useYup(inputs, { startDate: null, endDate: null });
    const projectId = yup.watch('projectId');
    const addressId = yup.watch('addressId');
    const modelId = yup.watch('modelId');

    useEffect(() => {
        if (projectId) {
            axios
                .get(`/project/getDepositSchedules/${projectId}`)
                .then((res) => {
                    setDepositSchedules(res.data);
                })
                .catch(function () {});

            axios
                .get(`/project/getListingBrokers/${projectId}`)
                .then((res) => {
                    setListingBrokers(res.data);
                })
                .catch(function () {});

            axios
                .get(`/project/getCommissions/${projectId}`)
                .then((res) => {
                    setCommissions(res.data);
                })
                .catch(function () {});

            axios
                .post(`/project/getIncentives`, { projectId })
                .then((res) => {
                    setIncentives(res.data);
                })
                .catch(function () {});
        }
    }, [projectId]);

    useEffect(() => {
        // Get models for this address whenever the address is changed
        if (addressId) {
            axios
                .get(`/model/getAddress/${addressId}`)
                .then((res) => {
                    setModels(res.data);
                })
                .catch(function (err) {});

            // Update defaults if address selection changes
            if (!slug && !referenceSlug) {
                // Clear model ID
                yup.setValue('modelId', null);
                // Update project ID
                yup.setValue(
                    'projectId',
                    addresses.filter((a) => a.id === addressId)[0]?.projectId
                );
            }
        }
    }, [addressId]);

    useEffect(() => {
        // Get models for this address whenever the address is changed
        if (modelId) {
            axios
                .get(`/model/get/${modelId}`)
                .then((res) => {
                    // Update default value for exterior square footage if creating a new, fresh listing
                    if (!slug && !referenceSlug) {
                        yup.setValue(
                            'exteriorSquareFeet',
                            res.data.exteriorSquareFeet
                        );
                    }
                })
                .catch(function (err) {});
        }
    }, [modelId]);

    if (valid) {
        return (
            <CMSForm
                title="Listing"
                api="auction"
                adminSlug="listings"
                data={data}
                attribute="slug"
                inputs={inputs}
                _yup={yup}
            >
                <AuctionFormReference watch={yup.watch} slug={slug} />
            </CMSForm>
        );
    } else {
        return <NotFound />;
    }
}

const AuctionTypeConfig = (props) => {
    const { yup, restricted } = props;
    const { watch } = yup;

    const auctionTypeId = +watch('auctionTypeId');

    return (
        <Box
            marginTop={2}
            marginBottom={2}
            padding={3}
            border="1px solid #eee"
            flex={1}
        >
            <AuctionTypeInputs
                yup={yup}
                restricted={restricted}
                auctionTypeId={auctionTypeId}
            />
            {auctionTypeId === auctionTypes.REVERSE.id && (
                <Button
                    variant="dark"
                    onClick={() => calculateReverseAuction(yup)}
                    fullWidth
                    disabled={restricted}
                >
                    Calculate End Date
                </Button>
            )}
        </Box>
    );
};

const AuctionTypeInputs = ({ yup, restricted, auctionTypeId }) => {
    //const isStandard = +auctionTypeId === auctionTypes.STANDARD.id;
    const isReverse = +auctionTypeId === auctionTypes.REVERSE.id;
    const isBuyNow = +auctionTypeId === auctionTypes.BUY_NOW.id;
    const isSilent = +auctionTypeId === auctionTypes.SILENT.id;
    const unset = !auctionTypeId;

    return (
        <>
            <div className="input-panel-container">
                <Field
                    yup={yup}
                    id="startDate"
                    input={{
                        dateTime: true,
                        step: 60,
                        disabled: restricted || unset,
                        description: 'Start date-time of the auction',
                        label: 'Auction Start Date',
                    }}
                />
                <Field
                    yup={yup}
                    id="endDate"
                    input={{
                        dateTime: true,
                        step: 60,
                        disabled: restricted || isReverse || unset,
                        description: (
                            <ul>
                                End date-time of the auction
                                <hr />
                                <li>
                                    <b>Reverse: </b>End date will be calculated
                                    based on the other configurations
                                </li>
                            </ul>
                        ),
                        label: 'Auction End Date',
                    }}
                />
            </div>

            <div className="input-panel-container">
                <Field
                    yup={yup}
                    id="startingBid"
                    input={{
                        number: true,
                        disabled: restricted || unset,
                        description: (
                            <ul>
                                <li>
                                    <b>Standard:</b> Initial minimum bid
                                </li>
                                <li>
                                    <b>Buy Now:</b> Constant purchase price
                                </li>
                                <li>
                                    <b>Reverse:</b> High starting amount to tick
                                    down from
                                </li>
                            </ul>
                        ),
                        label: 'Starting Bid ($)',
                    }}
                />
                <Field
                    yup={yup}
                    id="reserveAmount"
                    input={{
                        number: true,
                        disabled:
                            isBuyNow || (restricted && isReverse) || unset,
                        optional: !isReverse,
                        description: (
                            <ul>
                                <li>
                                    <b>Standard:</b> Minimum amount that a
                                    listing must sell at or above to complete
                                </li>
                                <li>
                                    <b>Buy Now:</b> N/A
                                </li>
                                <li>
                                    <b>Reverse:</b> Final minimum amount that
                                    the auction will tick down to before ending
                                </li>
                            </ul>
                        ),
                        label: 'Reserve Amount ($)',
                    }}
                />
                <Field
                    yup={yup}
                    id="bidIncrement"
                    input={{
                        number: true,
                        disabled: restricted || isBuyNow || unset,
                        description: (
                            <ul>
                                <li>
                                    <b>Standard:</b> Minimum increment between
                                    each bid
                                </li>
                                <li>
                                    <b>Buy Now:</b> N/A
                                </li>
                                <li>
                                    <b>Reverse:</b> Amount that the current bid
                                    requirement will tick down by each cycle
                                </li>
                            </ul>
                        ),
                        label: 'Bid Increment ($)',
                    }}
                />
                <Field
                    yup={yup}
                    id="timeIncrement"
                    input={{
                        number: true,
                        disabled: restricted || isBuyNow || isSilent || unset,
                        description: (
                            <ul>
                                <li>
                                    <b>Standard: </b> Time in milliseconds that
                                    represents the last-minute window for bids.
                                    Last-minute bids will change the end date of
                                    the auction to the `current time +
                                    timeIncrement`
                                </li>
                                <li>
                                    <b>Buy Now:</b> N/A
                                </li>
                                <li>
                                    <b>Reverse:</b> Rate in milliseconds that
                                    the purchase price will tick down at
                                </li>
                            </ul>
                        ),
                        label: 'Time Increment (ms)',
                    }}
                />
            </div>
        </>
    );
};

const calculateReverseAuction = (yup) => {
    const { setValue, getValues } = yup;

    axios
        .post(`/auction/calculateReverseAuction/`, getValues())
        .then((res) => {
            setValue('endDate', res.data);
        })
        .catch(function (err) {
            toast.error(
                err?.response?.data?.message || 'Unable to calculate end date.'
            );
        });
};

const InputUpgrades = ({ yup }) => {
    const { setValue, watch } = yup;

    const [upgrades, setUpgrades] = useState([]);
    const projectId = watch('projectId');

    useEffect(() => {
        // Get upgrades for this model
        if (projectId) {
            axios
                .post(`/project/getUpgrades`, { projectId: projectId })
                .then((res) => {
                    // TODO: Causes "Bad setState()" error message
                    setUpgrades(res.data);
                })
                .catch(function (err) {});
        }
    }, [projectId]);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'price',
            headerName: 'Price',
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
    ];

    return (
        <Box className="form-group" flex={1} marginTop={2}>
            <h6>Upgrades</h6>
            {upgrades.length === 0 && (
                <Typography
                    height={200}
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    fontSize={15}
                    color="text.secondary"
                >
                    There are no upgrades in the Project of the Address you
                    selected
                </Typography>
            )}
            {upgrades.map((upgrade, i) => (
                <Box key={upgrade.id} flex={1} marginTop={3}>
                    <Box display="flex">
                        <Typography fontWeight="bold">
                            {upgrade.title}
                        </Typography>

                        {upgrade.expired && (
                            <Typography
                                fontSize={14}
                                backgroundColor="background.danger"
                                border="1px solid"
                                borderColor="status.danger"
                                marginRight="auto"
                                marginLeft={2}
                                paddingLeft={2}
                                paddingRight={2}
                                borderRadius={5}
                            >
                                Expired
                            </Typography>
                        )}
                    </Box>
                    <Typography gutterBottom>{upgrade.description}</Typography>

                    <SelectionTable
                        rows={upgrade.upgradeOptions.map((upradeOption) => {
                            return {
                                id: upradeOption.id,
                                title: upradeOption.title,
                                price: `$${upradeOption.price.toLocaleString()}`,
                            };
                        })}
                        columns={columns}
                        control={yup.control}
                        setValue={yup.setValue}
                        multi={true}
                        id={`upgrades.${upgrade.id}`}
                    />
                </Box>
            ))}
        </Box>
    );
};

export default AuctionForm;
