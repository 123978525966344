import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import PopupForm from '../common/PopupForm';
import { useState } from 'react';

function ClientAccountCard({
    clientAccount,
    purchaseId,
    buttonLabel = 'Select',
    onSubmit = () => {
        window.location.reload();
    },
    borderColour = '#ccc',
    disabled,
}) {
    const [showSelectConfirmation, setShowSelectConfirmation] = useState(false);

    const bankAccount = clientAccount?.bankAccount;

    function handleSelect() {
        axios
            .post(`/postPurchase/selectBank`, {
                zumId: clientAccount.zumId,
                purchaseId: purchaseId,
            })
            .then((res) => {
                onSubmit();
            })
            .catch(function (err) {
                toast.error(
                    err?.response?.data?.message ||
                        'Unable to select bank account.'
                );
            });
    }

    if (!bankAccount) {
        return null;
    }

    const bankString = `${bankAccount.AccountNumber} - ${
        bankAccount.Institution || bankAccount.InstitutionNumber
    }`;

    return (
        <Box
            padding={1}
            paddingLeft={2.5}
            paddingRight={2.5}
            border={`1px solid ${borderColour}`}
            marginBottom={1}
            display="inline-block"
            borderRadius={100}
        >
            <Box display="flex">
                <Typography marginTop="auto" marginBottom="auto">
                    {bankString}
                </Typography>
                {purchaseId && (
                    <Button
                        variant="dark"
                        size="tiny"
                        onClick={() => setShowSelectConfirmation(true)}
                        sx={{ marginLeft: 2 }}
                        disabled={disabled}
                    >
                        {buttonLabel}
                    </Button>
                )}
            </Box>
            <PopupForm
                title="Connect Bank Account?"
                body={
                    <div>
                        <p>
                            Is this the correct account you would like to
                            connect for deposit payments related to this
                            transaction?
                        </p>
                        <p>
                            <b>{bankString}</b>
                        </p>
                    </div>
                }
                action={handleSelect}
                show={showSelectConfirmation}
                setShow={setShowSelectConfirmation}
            />
        </Box>
    );
}

export default ClientAccountCard;
