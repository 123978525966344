import { useState, useEffect } from 'react';
import { Button, Typography, Box } from '@mui/material';
import axios from 'axios';
import Loading from '../common/Loading';
import ViewAllContainer from './ViewAllContainer';
import AddBankButton from '../payment/AddBankButton';
import BusinessModal from '../modal/BusinessModal';

function Business() {
    const [businesses, setBusinesses] = useState();
    const [showCreate, setShowCreate] = useState(false);

    useEffect(() => {
        axios
            .get(`/business/getProfile`)
            .then((res) => {
                setBusinesses(res.data);
            })
            .catch(function () {});
    }, []);

    // Display loading indication
    if (!businesses) {
        return <Loading />;
    }

    return (
        <div className="user-content user-content-width">
            <ViewAllContainer title="Business" maxWidth={500}>
                {businesses.map((business) => (
                    <BusinessItem key={business.id} business={business} />
                ))}

                <Button
                    onClick={() => setShowCreate(!showCreate)}
                    display="inline-block"
                    variant="dark"
                    sx={{ marginBottom: 2 }}
                    size="small"
                    fullWidth
                >
                    Create Business
                </Button>
            </ViewAllContainer>

            <BusinessModal
                show={showCreate}
                onClose={() => setShowCreate(false)}
            />
        </div>
    );
}

function BusinessItem({ business }) {
    const [showEdit, setShowEdit] = useState(false);

    return (
        <Box marginBottom={4}>
            <Box marginBottom={2}>
                <Typography
                    marginBottom={2}
                >{`${business.title} (#${business.businessNumber})`}</Typography>
            </Box>
            <Box display="flex">
                <Button
                    variant="white"
                    type="border"
                    size="tiny"
                    sx={{ marginRight: 1 }}
                    onClick={() => setShowEdit(true)}
                >
                    Edit
                </Button>
                <AddBankButton businessId={business.id} />

                <BusinessModal
                    show={showEdit}
                    onClose={() => setShowEdit(false)}
                    _defaults={{
                        ...business,
                        proof: { media: business.proof },
                    }}
                />
            </Box>
        </Box>
    );
}

export default Business;
