import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import {
    Button,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Modal,
    Box,
    Typography,
    Grid,
} from '@mui/material';

import PaymentMethodCard from '../payment/PaymentMethodCard';
import AddPaymentModal from '../payment/AddPaymentModal';

import modalStatuses from '../../constants/modalStatuses';

import ModalCard from '../modal/ModalCard';
import { AccountContext } from '../supertokens/Account';
import { track } from '../../scripts/segment';
import auctionTypes from '../../constants/auctionTypes';

function BidModal({
    submitBidSuccess,
    submitBidFail,
    amount,
    auctionId,
    auctionTypeId,
    exclusiveId,
    show,
    onClose,
    createStatusModal,
}) {
    const account = useContext(AccountContext);

    const [processing, setProcessing] = useState();

    const [paymentMethod, setPaymentMethod] = useState();
    const [paymentMethods, setPaymentMethods] = useState();

    const [brokers, setBrokers] = useState([]);
    const [brokerId, setBrokerId] = useState('');

    const [addPaymentModalShow, setAddPaymentModalShow] = useState(false);

    const [feeCalculation, setFeeCalculation] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Get the signed in user's payment methods
        if (account.signedIn) {
            // TODO: Enable stripe
            //getPaymentMethods();
        }
    }, [account.signedIn]);

    useEffect(() => {
        let mounted = true;

        if (show) {
            axios
                .get(`/broker/getRecent/${auctionId}`)
                .then((res) => {
                    if (res.data && mounted) {
                        setBrokerId(res.data.id);
                    }
                })
                .catch(function () {});

            track('Bid Confirmation Shown', { auctionId, amount: +amount });
        }

        // Unmount
        return () => {
            mounted = false;
        };
    }, [show, auctionId, amount]);

    useEffect(() => {
        let mounted = true;

        // Calculate tax + service fee + total
        if (show) {
            axios
                .post(`/bid/calculateFee`, {
                    amount,
                    auctionId,
                })
                .then((res) => {
                    if (mounted) {
                        setFeeCalculation(res.data);
                        setLoading(false);
                    }
                })
                .catch(function () {});
        } else {
            setLoading(true);
        }

        // Unmount
        return () => {
            mounted = false;
        };
    }, [amount, show, auctionId]);

    const getPaymentMethods = () => {
        axios
            .get(`/stripe/methods`)
            .then((res) => {
                setPaymentMethods(res.data.methods);

                if (res.data.default) {
                    setPaymentMethod(res.data.default.id);
                } else if (res.data.methods.length > 0) {
                    setPaymentMethod(res.data.methods[0].id);
                }
            })
            .catch(function () {});

        axios
            .get(`/broker/getBrokers`)
            .then((res) => {
                setBrokers(res.data);
            })
            .catch(function () {});
    };

    const handleChangeMethod = (event) => {
        setPaymentMethod(event.target.value);
    };

    const handleSubmit = () => {
        setProcessing(true);
        axios
            .post(`/bid/create/`, {
                auctionId: auctionId,
                amount: amount,
                paymentMethod: paymentMethod,
                brokerId: exclusiveId || brokerId,
            })
            .then((res) => {
                const { message } = res.data;
                submitBidSuccess(message.title, message.body, message.link);
                setProcessing(false);
            })
            .catch(function (err) {
                if (err?.response?.data) {
                    const { title, body, message } = err.response.data;
                    createStatusModal(
                        modalStatuses.DANGER,
                        title || 'Error',
                        body ||
                            message ||
                            'An error has occured with your request. Please refresh the page and try again.'
                    );
                    submitBidFail();
                }
                setProcessing(false);
            });
    };

    const addPaymentMethodSuccess = () => {
        getPaymentMethods();
        setAddPaymentModalShow(false);
    };

    const BidSummaryItem = ({ title, amount, fontWeight }) => {
        return (
            <Grid container padding>
                <Grid item xs={6}>
                    <Typography
                        fontSize={16}
                        fontWeight={fontWeight || 'regular'}
                        color="dark"
                        letterSpacing={1.2}
                        lineHeight={1.6}
                    >
                        {title}:
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography
                        fontSize={16}
                        fontWeight={fontWeight || 'light'}
                        color="dark"
                        letterSpacing={1.2}
                        lineHeight={1.6}
                    >
                        {amount && `${parseAmount(amount || 0)} CAD`}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const parseAmount = (amount) => {
        const initialAmount = amount;

        if (amount < 0) {
            amount *= -1;
        }

        const value = parseFloat(amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
        });

        return `${initialAmount < 0 ? '-' : ''}$${value}`;
    };

    if (loading) {
        return null;
    }

    return (
        <Modal open={show} onClose={onClose} className="modal-center">
            <ModalCard show={show} onClose={onClose} width={400}>
                <Typography
                    fontSize={30}
                    fontWeight="bold"
                    paddingBottom={0.5}
                    fontFamily="urbanist"
                >
                    {modalTitle(auctionTypeId)}
                </Typography>
                <Typography fontSize={16} letterSpacing={0.5}>
                    {modalDescription(auctionTypeId)}
                </Typography>

                <Box paddingTop={5}>
                    <BidSummaryItem
                        title={bidSummaryLabel(auctionTypeId)}
                        amount={feeCalculation.amount}
                    />
                    {!feeCalculation.disabled && (
                        <Box>
                            <hr />
                            {feeCalculation.previousFees > 0 ? (
                                <>
                                    <BidSummaryItem
                                        title="Base Service Fee"
                                        amount={feeCalculation.baseFee}
                                    />
                                    <BidSummaryItem
                                        title="Previous Fees"
                                        amount={-feeCalculation.previousFees}
                                    />
                                </>
                            ) : (
                                <>
                                    <BidSummaryItem
                                        title="Service Fee"
                                        amount={feeCalculation.serviceFee}
                                    />
                                </>
                            )}
                            <BidSummaryItem
                                title="Tax"
                                amount={feeCalculation.tax}
                            />
                            <hr />
                            <BidSummaryItem
                                title="Total"
                                amount={feeCalculation.total}
                                fontWeight="bold"
                            />
                        </Box>
                    )}
                </Box>

                {!feeCalculation.disabled && (
                    <Box paddingTop={5}>
                        <PaymentMethodSelect
                            paymentMethod={paymentMethod}
                            paymentMethods={paymentMethods}
                            handleChangeMethod={handleChangeMethod}
                            setAddPaymentModalShow={setAddPaymentModalShow}
                        />
                    </Box>
                )}

                <BrokerSelect
                    brokerId={brokerId}
                    setBrokerId={setBrokerId}
                    brokers={brokers}
                    exclusiveId={exclusiveId}
                />

                <Button
                    variant="dark"
                    fullWidth
                    onClick={handleSubmit}
                    disabled={
                        processing ||
                        loading ||
                        (!paymentMethod && !feeCalculation.disabled)
                    }
                    fontFamily="urbanist"
                    sx={{ fontWeight: 'bold', marginTop: 4 }}
                >
                    {`${buttonLabel(auctionTypeId)} ${parseAmount(amount)}`}
                </Button>

                <AddPaymentModal
                    show={addPaymentModalShow}
                    onClose={() => setAddPaymentModalShow(false)}
                    success={addPaymentMethodSuccess}
                />
            </ModalCard>
        </Modal>
    );
}

const PaymentMethodSelect = ({
    paymentMethod,
    paymentMethods,
    handleChangeMethod,
    setAddPaymentModalShow,
}) => {
    return (
        <FormControl fullWidth>
            <InputLabel>
                <Typography
                    fontSize={18}
                    color="text.light"
                    backgroundColor="white"
                    paddingLeft={1}
                    paddingRight={2}
                    fontWeight="light"
                >
                    Payment Method
                </Typography>
            </InputLabel>
            <Select
                value={paymentMethod || ''}
                label="Payment Method"
                onChange={handleChangeMethod}
                disabled={!paymentMethods || paymentMethods.length === 0}
                style={{
                    borderRadius: 0,
                    marginBottom: 10,
                }}
            >
                {paymentMethods &&
                    paymentMethods.length > 0 &&
                    paymentMethods.map((method) => (
                        <MenuItem
                            key={method.id}
                            value={method.id}
                            style={{ width: '100%' }}
                        >
                            <PaymentMethodCard paymentMethod={method} />
                        </MenuItem>
                    ))}
            </Select>
            <Button
                onClick={() => setAddPaymentModalShow(true)}
                variant="grey"
                size="small"
                sx={{ fontWeight: 'bold' }}
            >
                + Add New Payment Method
            </Button>
        </FormControl>
    );
};

const BrokerSelect = ({ brokers, brokerId, setBrokerId, exclusiveId }) => {
    if (brokers?.length === 0 || exclusiveId) {
        return <Box marginBottom={2.5} />;
    }

    return (
        <FormControl
            fullWidth
            style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}
        >
            <Box flex={1}>
                <InputLabel>
                    <Typography
                        fontSize={18}
                        color="text.light"
                        backgroundColor="white"
                        paddingLeft={1}
                        paddingRight={2}
                        fontWeight="light"
                    >
                        Broker
                    </Typography>
                </InputLabel>
                <Select
                    value={exclusiveId || brokerId}
                    defaultValue={exclusiveId || brokerId}
                    label="Broker"
                    onChange={(e) => setBrokerId(e.target.value)}
                    disabled={exclusiveId || !brokers || brokers.length === 0}
                    style={{
                        borderRadius: 0,
                        marginBottom: 20,
                        width: '100%',
                    }}
                >
                    {brokers.map((item) => (
                        <MenuItem
                            key={item.id}
                            value={item.id}
                            style={{ width: '100%' }}
                        >
                            {item.user.username}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            {brokerId > 0 && (
                <Box marginLeft={1}>
                    <Button variant="danger" onClick={() => setBrokerId('')}>
                        X
                    </Button>
                </Box>
            )}
        </FormControl>
    );
};

function modalTitle(auctionTypeId) {
    switch (auctionTypeId) {
        case auctionTypes.BUY_NOW.id:
            return `Confirm Your Purchase`;
        default:
            return `Confirm Your Bid`;
    }
}

function modalDescription(auctionTypeId) {
    switch (auctionTypeId) {
        case auctionTypes.BUY_NOW.id:
            return `Are you sure you'd like to confirm your purchase of this unit? Next, you'll have the opportunity to add any additional purchasers and options to your unit before signing the purchase agreement.`;
        default:
            return `Are you sure you would like to submit this bid?`;
    }
}

function buttonLabel(auctionTypeId) {
    switch (auctionTypeId) {
        case auctionTypes.BUY_NOW.id:
            return `Buy`;
        default:
            return `Bid`;
    }
}
function bidSummaryLabel(auctionTypeId) {
    switch (auctionTypeId) {
        case auctionTypes.BUY_NOW.id:
            return `Amount`;
        default:
            return `Your Bid`;
    }
}

export default BidModal;
