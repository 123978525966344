import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NotFound from '../../pages/NotFound.js';
import CMSForm from './CMSForm.js';
import { provinces } from '../../scripts/validation.js';

function DeveloperForm() {
    const { username } = useParams();
    const [valid, setValid] = useState(true);
    const [data, setData] = useState();

    useEffect(() => {
        // Get existing developer data (if you are modifying one)
        if (username) {
            axios
                .get(`/developer/get/${username}`)
                .then((res) => {
                    setData({ ...res.data, username: username });
                })
                .catch(function () {
                    setValid(false);
                });
        }
    }, [username]);

    // Exit if bad data
    if (!valid) {
        return <NotFound />;
    }

    // Return the CMS form with the necessary inputs
    return (
        <CMSForm
            title="Developer"
            api="developer"
            adminSlug="developers"
            data={data}
            inputs={[
                [
                    {
                        id: 'username',
                        restricted: true,
                    },
                ],
                [
                    {
                        id: 'title',
                    },
                ],
                [
                    {
                        id: 'clientAccounts',
                        title: 'Zum Client Account',
                        inputs: [[{ id: 'zumId' }]],
                    },
                ],
                [
                    {
                        id: 'listingBrokers',
                        title: 'Listing Broker',
                        inputs: [
                            [{ id: 'brokerage' }],
                            [
                                { id: 'firstName' },
                                {
                                    id: 'middleName',
                                    optional: true,
                                },
                                { id: 'lastName' },
                            ],
                            [
                                {
                                    id: 'email',
                                    label: 'Broker Email',
                                },
                                {
                                    // TODO: Rename to {{position}}
                                    id: 'title',
                                    label: 'Position',
                                    optional: true,
                                },
                            ],
                            [
                                { id: 'country' },
                                {
                                    id: 'province',
                                    children: provinces,
                                },
                            ],
                            [{ id: 'city' }, { id: 'postalCode' }],
                            [
                                { id: 'streetAddress' },
                                {
                                    id: 'unit',
                                    label: 'Unit',
                                    optional: true,
                                },
                            ],
                        ],
                    },
                ],
            ]}
            attribute="username"
        />
    );
}

export default DeveloperForm;
