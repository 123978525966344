import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Button, Typography } from '@mui/material';
import { AccountContext } from '../supertokens/Account';

const WatchButton = ({
    groupId,
    width,
    variant = 'white',
    type = 'shadow',
    className,
}) => {
    const account = useContext(AccountContext);

    const [watching, setWatching] = useState(null);
    const toggleWatch = () => {
        if (!account.signedIn) {
            //setLoginModalShow(true);
            return;
        }

        axios
            .post(`/auction/${watching ? 'unfollow' : 'follow'}`, { groupId })
            .then((res) => {
                setWatching(res.data);
            })
            .catch(function () {});
    };

    useEffect(() => {
        let mounted = true;

        // Get watch status
        if (account.signedIn && groupId !== undefined && watching === null) {
            axios
                .get(`/auction/isWatching/${groupId}`)
                .then((res) => {
                    if (mounted) {
                        setWatching(res.data);
                    }
                })
                .catch(function () {});
        }

        // Unmount
        return () => {
            mounted = false;
        };
    });

    return (
        <>
            <Button
                variant={watching ? 'dark' : variant}
                type={type}
                sx={{
                    width: width,
                    zIndex: 30,
                    marginTop: 'auto',
                    marginBottom: 'auto',
                }}
                onClick={toggleWatch}
                className={className}
            >
                <Typography
                    fontSize={16}
                    fontWeight="bold"
                    letterSpacing={1.25}
                    fontFamily="urbanist"
                >
                    {watching ? 'Following' : 'Follow'}
                </Typography>
            </Button>
        </>
    );
};

export default WatchButton;
