import modalStatuses from './constants/modalStatuses';
import './assets/fonts/optima/style.css';

const muiTheme = {
    breakpoints: {
        values: {
            xs: 0,
            md: 900,
            lg: 1500,
        },
    },
    typography: {
        fontFamily: ['urbanist', 'sans-serif'].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        color: '#212529',
        heading: {
            fontFamily: 'superior-title',
        },
    },
    palette: {
        dark: '#212529',
        darker: '#222222',
        blue: '#045be0',
        tint: '#f5f2ed',
        brand: '#C19673',
        status: {
            ok: '#212529',
            info: '#1874FF',
            success: '#14B157',
            warning: '#ffe018',
            danger: '#C40B37',
        },
        background: {
            ok: '#fff',
            info: '#eaf2fd',
            success: '#eaf7ef',
            warning: '#fdffd1',
            danger: '#fff2f2',
        },
        text: {
            light: '#bbb',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.2)',
                    color: 'white',
                },
            },
            variants: [
                {
                    props: { variant: 'transparent' },
                    style: {
                        backgroundColor: '#00000000',
                        boxShadow: 'none',
                    },
                },
                {
                    props: { variant: 'dark' },
                    style: {
                        backgroundColor: '#212529',
                    },
                },
                {
                    props: { variant: 'admin' },
                    style: {
                        backgroundColor: '#3b1717',
                    },
                },
                {
                    props: { variant: 'development' },
                    style: {
                        backgroundColor: 'black',
                    },
                },
            ],
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: 100,
                    fontFamily: 'urbanist',
                    padding: '15px 45px',
                    fontWeight: '500',
                },
            },
            variants: [
                {
                    props: { variant: 'dark' },
                    style: {
                        color: 'white',
                        backgroundColor: '#212529',
                        ':hover': {
                            color: 'white',
                            backgroundColor: '#111',
                        },
                        '&:disabled': {
                            color: '#777',
                        },
                    },
                },
                {
                    props: { variant: 'danger' },
                    style: {
                        color: 'white',
                        backgroundColor: '#c92828',
                        ':hover': {
                            color: 'white',
                            backgroundColor: '#ba2222',
                        },
                        '&:disabled': {
                            color: '#777',
                        },
                    },
                },
                {
                    props: { variant: 'dark-transparent' },
                    style: {
                        color: 'white',
                        backgroundColor: '#21252990',
                        ':hover': {
                            color: 'white',
                            backgroundColor: '#ffffff10',
                        },
                        '&:disabled': {
                            color: '#777',
                        },
                    },
                },
                {
                    props: { variant: 'light' },
                    style: {
                        color: '#212529',
                        backgroundColor: '#dbdbdb',
                        ':hover': {
                            color: '#212529',
                            backgroundColor: '#fff',
                        },
                    },
                },
                {
                    props: { variant: 'grey' },
                    style: {
                        color: '#212529',
                        backgroundColor: '#dbdbdb',
                        ':hover': {
                            color: '#212529',
                            backgroundColor: '#ccc',
                        },
                    },
                },
                {
                    props: { variant: 'blue' },
                    style: {
                        color: 'white',
                        backgroundColor: '#1874FF',
                        ':hover': {
                            color: 'white',
                            backgroundColor: '#0052cf',
                        },
                        '&:disabled': {
                            color: '#a6a6a6',
                            backgroundColor: '#e0e0e0',
                        },
                    },
                },
                {
                    props: { variant: 'white' },
                    style: {
                        color: '#212529',
                        backgroundColor: 'white',
                        ':hover': {
                            color: '#212529',
                            backgroundColor: '#eaeaea',
                        },
                    },
                },
                {
                    props: { variant: 'white-transparent' },
                    style: {
                        color: 'white',
                        backgroundColor: 'transparent',
                        WebkitTransition: 'color 50ms ease-out',
                        MozTransition: 'color 50ms ease-out',
                        OTransition: 'color 50ms ease-out',
                        transition: 'color 50ms ease-out',
                        ':hover': {
                            color: 'white',
                            backgroundColor: 'transparent',
                            textDecoration: 'underline',
                            WebkitTransition: 'text-decoration 100ms ease-in',
                            MozTransition: 'text-decoration 100ms ease-in',
                            OTransition: 'text-decoration 100ms ease-in',
                            transition: 'text-decoration 100ms ease-in',
                        },
                    },
                },
                {
                    props: { variant: 'yellow' },
                    style: {
                        color: 'white',
                        backgroundColor: '#ffb129',
                        ':hover': {
                            color: 'white',
                            backgroundColor: '#f5a720',
                        },
                        border: '1px solid #ffb129 !important',
                    },
                },
                {
                    props: { variant: 'brand' },
                    style: {
                        color: 'white',
                        backgroundColor: '#C19673',
                        ':hover': {
                            color: 'white',
                            backgroundColor: '#b08868',
                        },
                        '&:disabled': {
                            color: '#917660',
                        },
                    },
                },
                {
                    props: { variant: 'blue-transparent' },
                    style: {
                        color: '#1874FF',
                        ':hover': {
                            color: '#1057c2',
                            backgroundColor: 'transparent',
                        },
                    },
                },
                {
                    props: { variant: 'plain' },
                    style: {
                        backgroundColor: 'transparent',
                        ':hover': {},
                    },
                },
                {
                    props: { size: 'tiny' },
                    style: {
                        fontSize: 13,
                        padding: '0.6em 2em',
                    },
                },
                {
                    props: { size: 'small' },
                    style: {
                        fontSize: 15,
                        padding: '0.8em 1em 0.8em 1em',
                    },
                },
                {
                    props: { size: 'medium' },
                    style: {
                        fontSize: 16,
                    },
                },
                {
                    props: { size: 'large' },
                    style: {
                        fontSize: 17,
                    },
                },
                {
                    props: { type: 'border' },
                    style: {
                        border: '1px solid',
                    },
                },
                {
                    props: { type: 'shadow' },
                    style: {
                        boxShadow: '0px 6px 7px 1px #00000062',
                    },
                },
            ],
        },
        MuiCard: {
            variants: [
                {
                    props: { variant: 'modal' },
                    style: {
                        /*
                        position: 'fixed',
                        CAUTION: Causes the stripe modal content to blur:
                        transform: 'translate(-50%, -50%)',
                        */
                        position: 'relative',
                        backgroundColor: 'white',
                        boxShadow: 24,
                        maxHeight: '100%',
                        maxWidth: '100%',
                        overflowY: 'auto',
                        outline: 'none',
                    },
                },
                {
                    props: { type: modalStatuses.SUCCESS.value },
                    style: {
                        backgroundColor: '#f4fff8',
                    },
                },
                {
                    props: { type: modalStatuses.DANGER.value },
                    style: {
                        backgroundColor: '#fff2f2',
                    },
                },
                {
                    props: { type: modalStatuses.INFO.value },
                    style: {
                        backgroundColor: '#f4faff',
                    },
                },
            ],
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
            variants: [
                {
                    props: { type: 'dropdown' },
                    style: {
                        '> .MuiPaper-root': {
                            border: '1px solid #ccc',
                            borderRadius: 20,
                            marginTop: 5,
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 5px 1px',
                        },
                        ul: {
                            width: '100%',
                        },
                    },
                },
                {
                    props: { border: 'split' },
                    style: {
                        ul: {
                            '> *:not(:last-child)': {
                                borderBottom: '1px solid #ccc',
                            },
                        },
                    },
                },
            ],
        },
        MuiBadge: {
            variants: [
                {
                    props: { type: 'notifications' },
                    style: {
                        '> span': {
                            paddingTop: 2,
                            backgroundColor: '#1874FF',
                        },
                    },
                },
            ],
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: '#212529 !important',
                },
            },
            variants: [
                {
                    props: { variant: 'light' },
                    style: {
                        borderColor: '#ccc !important',
                    },
                },
            ],
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: 'white',
                    color: '#212529',
                    borderRadius: 20,
                    marginTop: 5,
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 3px 1px',
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    backgroundColor: '#212529 !important',
                    color: 'white !important',
                    padding: 1,
                    borderRadius: 200,
                    marginRight: 20,
                    fontSize: 34,
                },
                text: {
                    fill: '#212529',
                    fontWeight: 500,
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    fontSize: 18,
                    fontWeight: '600 !important',
                },
                '&.Mui-completed': {
                    fontSize: 18,
                    fontWeight: '600 !important',
                },
            },
        },
        MuiStepContent: {
            styleOverrides: {
                root: {
                    marginLeft: 16,
                },
                transition: {
                    marginLeft: 26,
                },
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                root: {
                    marginLeft: 16,
                },
            },
        },
    },
};

export default muiTheme;
