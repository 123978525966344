import { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    Grid,
    IconButton,
    LinearProgress,
    Link,
    Tooltip,
    Typography,
} from '@mui/material';
import DynamicIcon from '../../components/common/DynamicIcon';
import Loading from '../../components/common/Loading';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getPaymentTypes, useYup } from '../../scripts/cms';
import Field from '../../components/cms/Field';
import CollapseContainer from '../../components/common/CollapseContainer';
import NoData from '../../components/common/NoData';
import { useParams } from 'react-router-dom';
import { formatText, formatTypes } from '../../scripts/style';
import PopupForm from '../../components/common/PopupForm';
import { auctionTypesById } from '../../constants/auctionTypes';

function PostPurchase() {
    const { slug } = useParams();

    const [auctions, setAuctions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        reload();
    }, [slug]);

    function reload() {
        axios
            .post(`/postPurchase/getAdmin`, { slug: slug })
            .then((res) => {
                setAuctions(res.data);
            })
            .catch(function () {})
            .finally(function () {
                setLoading(false);
            });
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="admin-content">
            {auctions.length === 0 && (
                <NoData description="There are no sold auctions to display." />
            )}
            {auctions.map((auction, i) => (
                <PurchaseItem
                    key={auction.id}
                    auction={auction}
                    reload={reload}
                    setLoading={setLoading}
                    slug={slug}
                />
            ))}
        </div>
    );
}

function PurchaseItem({ auction, reload, setLoading, slug }) {
    const [showInfo, setShowInfo] = useState(false);

    const { property, purchase, timing, depositSchedule } = auction;
    const { address } = property;
    const { project } = address;

    function handleCreateAPS(auctionId) {
        setLoading(true);

        axios
            .post(`/postPurchase/createEnvelope`, { auctionId })
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            })
            .finally(function () {
                setLoading(false);
            });
    }
    function handleVoidAPS(auctionId, body) {
        setLoading(true);

        axios
            .post(`/postPurchase/voidAPS`, { auctionId, ...body })
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            })
            .finally(function () {
                setLoading(false);
            });
    }

    function handleCreateICA(auctionId) {
        setLoading(true);

        axios
            .post(`/postPurchase/createCommissionEnvelope`, { auctionId })
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            })
            .finally(function () {
                setLoading(false);
            });
    }
    function handleVoidICA(auctionId, body) {
        setLoading(true);

        axios
            .post(`/postPurchase/voidICA`, { auctionId, ...body })
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            })
            .finally(function () {
                setLoading(false);
            });
    }

    function handleCreatePayment(auctionId) {
        setLoading(true);

        axios
            .post(`/postPurchase/createPayment`, { auctionId })
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            })
            .finally(function () {
                setLoading(false);
            });
    }

    function handleExtendPostPurchase(auctionId) {
        setLoading(true);

        axios
            .post(`/postPurchase/extend`, { auctionId })
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            })
            .finally(function () {
                setLoading(false);
            });
    }

    function handleCancelPurchase(auctionId) {
        setLoading(true);

        axios
            .post(`/postPurchase/cancel`, { auctionId })
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            })
            .finally(function () {
                setLoading(false);
            });
    }

    function handleRefreshAmounts(auctionId) {
        setLoading(true);

        axios
            .post(`/postPurchase/refreshAmounts`, { auctionId })
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            })
            .finally(function () {
                setLoading(false);
            });
    }

    function handleUpdateCRM(auctionId, target) {
        setLoading(true);

        axios
            .post(`/hotglue/update${target}/${auctionId}`)
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            })
            .finally(function () {
                setLoading(false);
            });
    }

    function handleDealSummary(auctionId) {
        axios
            .post(`/developer/createDealSummary/${auctionId}`)
            .then((res) => {
                window.location.reload();
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            })
            .finally(function () {
                setLoading(false);
            });
    }

    return (
        <Box
            backgroundColor="white"
            padding={6}
            marginBottom={2}
            border={slug ? '2px solid #045be0' : ''}
        >
            <Grid container>
                <Grid item xs={4}>
                    <Typography fontWeight="bold">
                        {project.title}
                        {property.unitNumber ? ` - ${property.unitNumber}` : ''}
                    </Typography>
                    <Typography
                        component={Link}
                        href={`/listing/${auction.slug}`}
                    >
                        {auction.title}
                    </Typography>
                </Grid>
                <Grid item xs={4} textAlign="center">
                    <Box>
                        <IconButton onClick={() => setShowInfo(true)}>
                            <DynamicIcon icon="Info" colour="dark" size={30} />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={4} textAlign="right">
                    <Typography>{`${address.province}, ${address.city}, ${address.postalCode}`}</Typography>
                    <Typography>{`${address.streetAddress}${
                        property.unit ? `, ${property.unit}` : ''
                    }`}</Typography>
                </Grid>
            </Grid>
            <hr />
            <Box display="flex">
                <Box flex={1}>
                    <Grid container>
                        <Section title="Summary">
                            <Status
                                title="Post Purchase"
                                value={purchase !== null}
                            />
                            <Status
                                title={
                                    purchase?.envelopeId &&
                                    purchase?.envelope?.cancellationDate
                                        ? 'Envelope Sent (Cancelled)'
                                        : 'Envelope Sent'
                                }
                                value={
                                    purchase?.envelopeId &&
                                    !purchase?.envelope?.cancellationDate
                                }
                            />
                            <Status
                                title="Docusign Completed"
                                value={timing.docusign.status === 'done'}
                            />
                            <Status
                                title={'Payment Method Chosen'}
                                value={
                                    purchase?.clientAccountId ||
                                    purchase?.manualPaymentOptionId
                                }
                            />
                            <Status
                                title="Payments Created"
                                value={purchase?.scheduleDate}
                            />
                            {auction.isRescinded && (
                                <Status
                                    title="Purchase Cancelled (Rescinded)"
                                    value={!auction.isRescinded}
                                />
                            )}
                            {auction.isReleased && (
                                <Status
                                    title="Purchase Cancelled (Released)"
                                    value={!auction.isReleased}
                                />
                            )}
                            <Status
                                title="Commission Agreement"
                                value={!!purchase?.commissionEnvelope?.signDate}
                            />
                        </Section>
                        <Section title="ID Verifications">
                            <Verifications purchase={purchase} />
                        </Section>
                        {depositSchedule && (
                            <Section
                                title={`Deposit Schedule (${depositSchedule.title})`}
                            >
                                <Payments
                                    deposits={depositSchedule?.deposits}
                                />
                            </Section>
                        )}
                    </Grid>
                    <Section title="Timeline">
                        <SegmentedProgressBar
                            inputs={[
                                {
                                    title: 'Post-Purchase',
                                    ...timing.postPurchase,
                                },
                                {
                                    title: 'IDV',
                                    ...timing.idv,
                                },
                                {
                                    title: 'Docusign',
                                    ...timing.docusign,
                                },
                            ]}
                        />
                    </Section>
                    <Section title="Payments">
                        <SegmentedProgressBar
                            inputs={timing.deposits.map((deposit, i) => ({
                                ...deposit,
                                title: `Deposit #${i + 1}`,
                            }))}
                            reverse
                            Child={DepositInfo}
                            childAttribute="deposit"
                            childProps={{ purchase, reload }}
                        />
                    </Section>
                    {purchase?.purchaseCommissionPayments?.length > 0 && (
                        <Section title="Commissions">
                            <SegmentedProgressBar
                                inputs={purchase.purchaseCommissionPayments.map(
                                    (purchaseCommissionPayment, i) => ({
                                        purchaseCommissionPayment,
                                        title: `Commission #${i + 1}`,
                                        status: purchaseCommissionPayment.paid
                                            ? 'done'
                                            : undefined,
                                    })
                                )}
                                reverse
                                Child={CommissionInfo}
                                childAttribute="purchaseCommissionPayment"
                                //childProps={{ purchase, reload }}
                            />
                        </Section>
                    )}
                    <NoteInput auction={auction} />
                </Box>
                <Box
                    width={200}
                    borderLeft="1px solid #eee"
                    marginLeft={2}
                    paddingLeft={2}
                >
                    <Typography fontSize={16} fontWeight="bold" gutterBottom>
                        Actions
                    </Typography>
                    <ButtonList
                        groups={[
                            {
                                title: 'Purchase',
                                inputs: [
                                    {
                                        title: 'Extend Post-Purchase',
                                        disabled:
                                            !!purchase ||
                                            !auction.purchaseExpirationDate,
                                        handleClick: () =>
                                            handleExtendPostPurchase(
                                                auction.id
                                            ),
                                    },
                                    {
                                        title: 'Schedule Deposits',
                                        disabled:
                                            purchase?.scheduleDate !== null ||
                                            (purchase?.clientAccountId ===
                                                null &&
                                                purchase?.manualPaymentOption ===
                                                    null) ||
                                            !purchase?.envelope?.signDate,
                                        handleClick: () =>
                                            handleCreatePayment(auction.id),
                                    },
                                    {
                                        title: 'Cancel Purchase',
                                        disabled:
                                            !auction.isReleased &&
                                            !auction.isRescinded &&
                                            purchase,
                                        handleClick: () =>
                                            handleCancelPurchase(auction.id),
                                    },
                                    {
                                        title: 'Refresh Amounts',
                                        disabled:
                                            !purchase ||
                                            (purchase?.envelopeId &&
                                                !purchase?.envelope
                                                    ?.cancellationDate) ||
                                            (purchase?.commissionEnvelopeId &&
                                                !purchase?.commissionEnvelope
                                                    ?.cancellationDate),
                                        handleClick: () =>
                                            handleRefreshAmounts(auction.id),
                                    },
                                ],
                            },
                            {
                                title: 'DocuSign - APS',
                                inputs: [
                                    {
                                        title: 'Send APS',
                                        disabled:
                                            !purchase ||
                                            !auction.sold ||
                                            (purchase.envelopeId !== null &&
                                                !purchase.envelope
                                                    ?.cancellationDate),
                                        handleClick: () =>
                                            handleCreateAPS(auction.id),
                                    },
                                    {
                                        title: 'Void APS',
                                        disabled:
                                            !!purchase?.envelope?.signDate ||
                                            !purchase?.envelopeId ||
                                            !!purchase?.envelope
                                                ?.cancellationDate,
                                        handleClick: (body) =>
                                            handleVoidAPS(auction.id, body),
                                        inputs: [
                                            [
                                                {
                                                    id: 'reason',
                                                    rows: 4,
                                                    optional: true,
                                                    description:
                                                        'This reason will be visible to all signers.',
                                                },
                                            ],
                                        ],
                                    },
                                ],
                            },
                            {
                                title: 'DocuSign - ICA',
                                inputs: [
                                    {
                                        title: 'Send ICA',
                                        disabled:
                                            !purchase ||
                                            !auction.sold ||
                                            (purchase.commissionEnvelopeId !==
                                                null &&
                                                !purchase.commissionEnvelope
                                                    ?.cancellationDate),
                                        handleClick: () =>
                                            handleCreateICA(auction.id),
                                    },
                                    {
                                        title: 'Void ICA',
                                        disabled:
                                            !!purchase?.commissionEnvelope
                                                ?.signDate ||
                                            !purchase?.commissionEnvelopeId ||
                                            !!purchase?.commissionEnvelope
                                                ?.cancellationDate,
                                        handleClick: (body) =>
                                            handleVoidICA(auction.id, body),
                                        inputs: [
                                            [
                                                {
                                                    id: 'reason',
                                                    rows: 4,
                                                    optional: true,
                                                    description:
                                                        'This reason will be visible to all signers.',
                                                },
                                            ],
                                        ],
                                    },
                                ],
                            },
                            {
                                title: 'Other',
                                inputs: [
                                    {
                                        title: 'Update Salesforce',
                                        handleClick: () =>
                                            handleUpdateCRM(
                                                auction.id,
                                                'Salesforce'
                                            ),
                                    },
                                    {
                                        title: 'Update Google Sheets',
                                        handleClick: () =>
                                            handleUpdateCRM(
                                                auction.id,
                                                'GoogleSheets'
                                            ),
                                    },
                                    {
                                        title: 'Re-Send Deal Summary',
                                        handleClick: () =>
                                            handleDealSummary(auction.id),
                                        disabled: !purchase?.envelope?.signDate,
                                    },
                                ],
                            },
                        ]}
                    />
                </Box>
            </Box>
            <PopupForm
                title="Additional Info"
                show={showInfo}
                setShow={setShowInfo}
                body={<PurchaseInfo auction={auction} />}
                width={450}
                height={500}
            />
        </Box>
    );
}

function PurchaseInfo({ auction }) {
    const { property, purchase, timing, depositSchedule } = auction;
    const { address } = property;
    const { project } = address;

    return (
        <Box padding={1} marginTop={1} paddingBottom={8}>
            <Line
                title="User"
                value={formatText(auction.bids[0].user.username)}
                bold
            />

            <Line />

            <Line
                title="Purchase Amount"
                value={formatText(auction.bids[0].amount, formatTypes.MONEY)}
            />
            <Line
                title="Upgrade Price"
                value={formatText(purchase?.upgradePrice, formatTypes.MONEY)}
            />
            <Line
                title="Final Price"
                value={formatText(purchase?.finalPrice, formatTypes.MONEY)}
                bold
            />

            <Line />

            <Line
                title="Listing Type"
                value={auctionTypesById[auction.auctionTypeId]}
            />
            <Line
                title="Initial Price"
                value={formatText(auction.startingBid, formatTypes.MONEY)}
            />
            <Line
                title="Reserve Amount"
                value={formatText(auction.reserveAmount, formatTypes.MONEY)}
            />
            <Line
                title="Bid Increment"
                value={formatText(auction.bidIncrement, formatTypes.MONEY)}
            />
            <Line title="Time Increment" value={auction.timeIncrement} />

            <Line />

            <Line
                title="Start Date"
                value={formatText(auction.startDate, formatTypes.DATE)}
                tooltip={formatText(auction.startDate, formatTypes.DATETIME)}
            />
            <Line
                title="End Date"
                value={formatText(auction.endDate, formatTypes.DATE)}
                tooltip={formatText(auction.endDate, formatTypes.DATETIME)}
            />

            <Line />

            <Line
                title="Post-Purchase Expires"
                value={formatText(
                    auction.purchaseExpirationDate,
                    formatTypes.DATE
                )}
                tooltip={formatText(
                    auction.purchaseExpirationDate,
                    formatTypes.DATETIME
                )}
            />
            <Line
                title="Envelope Date"
                value={formatText(
                    purchase?.envelope?.sendDate,
                    formatTypes.DATE
                )}
                tooltip={formatText(
                    purchase?.envelope?.sendDate,
                    formatTypes.DATETIME
                )}
            />
            <Line
                title="Cancellation Date"
                value={formatText(
                    purchase?.envelope?.cancellationDate,
                    formatTypes.DATE
                )}
                tooltip={formatText(
                    purchase?.envelope?.cancellationDate,
                    formatTypes.DATETIME
                )}
            />
            <Line
                title="Sign Date"
                value={formatText(
                    purchase?.envelope?.signDate,
                    formatTypes.DATE
                )}
                tooltip={formatText(
                    purchase?.envelope?.signDate,
                    formatTypes.DATETIME
                )}
            />
            <Line
                title="Schedule Date"
                value={formatText(purchase?.scheduleDate, formatTypes.DATE)}
                tooltip={formatText(
                    purchase?.scheduleDate,
                    formatTypes.DATETIME
                )}
            />

            <Line />

            <Line title="Purchase ID" value={purchase?.id} minor />
            <Line title="Auction ID" value={auction.id} minor />
            <Line title="Property ID" value={property.id} minor />
            <Line title="Model ID" value={property.model.id} minor />
            <Line title="Address ID" value={address.id} minor />
            <Line title="Project ID" value={project.id} minor />

            <Line />

            <Line
                title="DepositSchedule ID"
                value={auction.depositScheduleId}
                minor
            />
            <Line
                title="Docupilot Template ID"
                value={auction.docupilotTemplateId}
                minor
            />
            <Line
                title="Listing Broker ID"
                value={auction.listingBrokerId}
                minor
            />
            <Line title="Business ID" value={purchase?.businessId} minor />
            <Line title="Envelope ID" value={purchase?.envelopeId} minor />
            <Line
                title="Commission Envelope ID"
                value={purchase?.commissionEnvelopeId}
                minor
            />
            <Line
                title="Client Account ID"
                value={purchase?.clientAccount?.zumId}
                minor
            />
        </Box>
    );
}

function ButtonList({ groups = [] }) {
    const [curr, setCurr] = useState();

    return (
        <Box marginBottom={3}>
            {groups.map((group, i) => (
                <Box key={i} flex={1}>
                    <Typography marginBottom={1}>{group.title}</Typography>
                    {group.inputs.map((input, j) => (
                        <Button
                            key={j}
                            variant="white"
                            size="tiny"
                            disabled={input.disabled}
                            onClick={() => setCurr(input)}
                            fullWidth
                            sx={{ marginBottom: 1 }}
                        >
                            {input.title}
                        </Button>
                    ))}
                </Box>
            ))}
            <PopupForm
                title={`Are you sure you want to '${curr?.title}'`}
                body={curr?.body}
                action={curr?.handleClick}
                show={!!curr}
                setShow={setCurr}
                inputs={curr?.inputs}
            />
        </Box>
    );
}

function NoteInput({ auction }) {
    const yup = useYup([[{ id: 'notes' }]], { notes: auction.notes });

    function handleSubmit() {
        axios
            .post(`/auction/updateNotes`, {
                auctionId: auction.id,
                notes: yup.getValues().notes,
            })
            .then((res) => {
                toast.success('Updated notes');
            })
            .catch(function (err) {
                toast.error(
                    err.response.data?.message || err.response?.statusText
                );
            });
    }

    return (
        <Box marginTop={3}>
            <CollapseContainer
                title="Notes"
                expandDefault={auction.notes !== null}
                backgroundColor="white"
                color="#222"
            >
                <Box backgroundColor="white">
                    <Field
                        id="notes"
                        yup={yup}
                        input={{
                            rows: 8,
                            hideLabel: true,
                        }}
                    />
                    <Button
                        fullWidth
                        variant="dark"
                        size="tiny"
                        onClick={handleSubmit}
                        sx={{ borderRadius: 0 }}
                    >
                        Update
                    </Button>
                </Box>
            </CollapseContainer>
        </Box>
    );
}

function SegmentedProgressBar({
    inputs = [],
    reverse = false,
    Child,
    childAttribute,
    childProps,
}) {
    function DateString({ dateString }) {
        if (!dateString) {
            return '---';
        }

        return moment(dateString).format('MMM D h:mm A');
    }

    return (
        <Box display="flex">
            {inputs.map((input, i) => {
                const startDate = moment(input.startDate);
                const endDate = moment(input.endDate);
                const now = moment();

                const timeRequired = startDate.diff(endDate, 'seconds');
                const timeTaken = startDate.diff(now, 'seconds');

                let percent = timeTaken / timeRequired;

                // TODO: Constant definiton for these statuses like on backend? Ideally a better solution than 2 files.
                let statusColour = '#aaa';
                let icon = null;
                if (input.status === 'started') {
                    statusColour = 'status.info';
                } else if (input.status === 'late') {
                    statusColour = 'status.danger';
                    icon = 'Cancel';
                } else if (input.status === 'done') {
                    statusColour = 'status.success';
                    icon = 'CheckCircle';
                }

                // 0% if not started / no date
                if (
                    !input.startDate ||
                    !input.endDate ||
                    now.isBefore(startDate)
                ) {
                    percent = 0;
                }
                // 100% if past end date
                else if (now.isAfter(endDate)) {
                    percent = 1;
                }

                return (
                    <Box
                        key={i}
                        flex={1}
                        marginRight={
                            i !== inputs.length - 1 ? '5px' : undefined
                        }
                    >
                        <Box
                            width="100%"
                            display="flex"
                            position="relative"
                            color={statusColour}
                        >
                            <LinearProgress
                                value={percent * 100}
                                variant="determinate"
                                color="inherit"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                }}
                            />
                            <Box
                                display="flex"
                                marginTop={0.25}
                                marginBottom={0.25}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'relative',
                                }}
                            >
                                <Typography
                                    textAlign="center"
                                    fontSize={13}
                                    color="white"
                                    paddingLeft={1}
                                    paddingRight={1}
                                    borderRadius={100}
                                    backgroundColor="#00000050"
                                    margin="auto"
                                >
                                    {icon && (
                                        <DynamicIcon
                                            icon={icon}
                                            size={15}
                                            sx={{
                                                marginBottom: 0.25,
                                                marginRight: 0.5,
                                            }}
                                        />
                                    )}
                                    {input.title}
                                </Typography>
                            </Box>
                        </Box>
                        <Box width="100%" display="flex">
                            {i === 0 && (
                                <Typography flex={1} fontSize={14}>
                                    <DateString dateString={input.startDate} />
                                </Typography>
                            )}
                            {i > 0 && !reverse && (
                                <Typography flex={1} fontSize={14}>
                                    <DateString dateString={input.startDate} />
                                </Typography>
                            )}
                            {(i === inputs.length - 1 || reverse) && (
                                <Typography
                                    flex={1}
                                    fontSize={14}
                                    textAlign="right"
                                >
                                    <DateString dateString={input.endDate} />
                                </Typography>
                            )}
                        </Box>
                        {Child && childAttribute && (
                            <Child
                                data={input[childAttribute]}
                                props={childProps}
                            />
                        )}
                    </Box>
                );
            })}
        </Box>
    );
}

function Line({
    title,
    value,
    minor,
    gap,
    noDivider,
    tooltip,
    description,
    bold,
    size = 14,
}) {
    // Return a gap if no values are given
    if (!title && !value) {
        return <Box marginTop={2} />;
    }

    return (
        <Box
            display="flex"
            color={minor ? '#aaa' : 'dark'}
            borderBottom={
                gap ? '1px solid #bbb' : noDivider ? '' : '1px dotted #ccc'
            }
            padding={0.2}
            paddingTop={0.4}
        >
            <Tooltip title={description} placement="right">
                <Typography
                    fontSize={14}
                    fontWeight={bold ? 'bold' : 'medium'}
                    marginRight="auto"
                >
                    {title}:
                </Typography>
            </Tooltip>
            <Tooltip title={tooltip} placement="left">
                <Typography
                    fontSize={size}
                    fontWeight={bold ? 'bold' : 'regular'}
                >
                    {value === null || value === undefined
                        ? formatText(value)
                        : value}
                </Typography>
            </Tooltip>
        </Box>
    );
}

function DepositInfo({ data: deposit, props }) {
    const [showCancel, setShowCancel] = useState(false);
    const [showPaymentType, setShowPaymentType] = useState(false);

    const { depositPayment } = deposit;
    const { fund, withdraw, refund, manualPayment, paymentTypeId } =
        depositPayment;

    const { purchase, reload } = props;

    // Exit if no data
    if (!deposit || !purchase) {
        return null;
    }

    let isDepositActive = false;
    let isPAD = false;
    let isManual = false;
    switch (depositPayment.paymentTypeId) {
        case 'PAD':
            if (
                fund &&
                fund?.status !== 'Cancelled' &&
                fund?.status !== 'Failed'
            ) {
                isDepositActive = true;
            }
            isPAD = true;
            break;

        case 'MANUAL':
            if (manualPayment && manualPayment.completedAt) {
                isDepositActive = true;
            }
            isManual = true;
            break;

        default:
            isDepositActive = true;
            break;
    }

    function handleCancel() {
        axios
            .post(`/deposit/cancel`, {
                depositPaymentId: depositPayment.id,
            })
            .then((res) => {
                setShowCancel(false);
                reload();
            })
            .catch(function (err) {
                toast.error(err?.response?.data?.message || 'Request failed');
            });
    }

    // function handleStatus(changes) {
    //     axios
    //         .post(`/deposit/updateStatus`, changes)
    //         .then((res) => {
    //             setShowStatus(false);
    //             reload();
    //         })
    //         .catch(function (err) {
    //             toast.error(err?.response?.data?.message || 'Request failed');
    //         });
    // }

    function handleChangePaymentType(changes) {
        axios
            .post(`/deposit/changePaymentType`, changes)
            .then((res) => {
                setShowPaymentType(false);
                reload();
            })
            .catch(function (err) {
                toast.error(err?.response?.data?.message || 'Request failed');
            });
    }

    return (
        <Box padding={1} marginTop={1} backgroundColor="#f4f4f4">
            <Line title="Type" value={depositPayment.paymentTypeId} />
            <Line
                title="Amount"
                value={`${formatText(
                    depositPayment.amount,
                    formatTypes.MONEY
                )} (${
                    deposit.percent !== null ? `${deposit.percent}%` : 'Fixed'
                })`}
            />
            <Line
                title="Due"
                value={formatText(
                    deposit.dueDays !== null
                        ? `${deposit.dueDays} days after ${
                              deposit.scheduleFromFirm ? 'firm' : 'signing'
                          }`
                        : deposit.dueString
                )}
            />
            <Line
                title="Due Date"
                value={formatText(depositPayment.dueDate, formatTypes.DATE)}
                gap
            />

            <Line
                title="Deposit Status"
                value={formatText(fund?.status)}
                minor={!isPAD}
            />
            <Line
                title="Received Date"
                value={formatText(fund?.completedAt, formatTypes.DATE)}
                tooltip={formatText(fund?.completedAt, formatTypes.DATETIME)}
                gap
                minor={!isPAD}
            />

            {depositPayment.retried && (
                <Line
                    title="Retry Date"
                    value={formatText(fund?.scheduleDate, formatTypes.DATE)}
                    minor={!isPAD}
                />
            )}

            <Line
                title="Withdraw Status"
                value={formatText(withdraw?.status)}
                minor={!isPAD}
            />
            <Line
                title="Withdraw Date"
                value={formatText(withdraw?.completedAt, formatTypes.DATE)}
                tooltip={formatText(
                    withdraw?.completedAt,
                    formatTypes.DATETIME
                )}
                minor={!isPAD}
                gap
            />

            {/* <Line
                title="Refund Status"
                value={formatText(refund?.status)}
                minor={!refund}
            />
            <Line
                title="Refund Date"
                value={formatText(
                    refund?.completedAt,
                    formatTypes.DATE
                )}
                tooltip={formatText(
                    refund?.completedAt,
                    formatTypes.DATETIME
                )}
                minor={!refund}
                gap
            /> */}

            <Line
                title={`${
                    manualPayment?.manualPaymentOption?.title || 'Manual'
                } Status`}
                value={formatText(manualPayment?.status)}
                minor={!isManual}
            />
            <Line
                title={`${
                    manualPayment?.manualPaymentOption?.title || 'Manual'
                } Received`}
                value={formatText(manualPayment?.completedAt, formatTypes.DATE)}
                tooltip={formatText(
                    manualPayment?.completedAt,
                    formatTypes.DATETIME
                )}
                gap
                minor={!isManual}
            />

            <Line title="Deposit ID" value={formatText(deposit.id)} minor />
            <Line
                title="Deposit Payment ID"
                value={formatText(depositPayment.id)}
                minor
                gap
            />
            <Line
                title="Fund ID"
                value={formatText(fund?.id)}
                size={10}
                minor
            />
            <Line
                title="Withdraw ID"
                value={formatText(withdraw?.id)}
                size={10}
                minor
            />
            {/* <Line
                title="Refund ID"
                value={formatText(refund?.id)}
                size={10}
                minor
            /> */}

            <Box flexGrow={1}>
                <DepositButton
                    title="Change Type"
                    handleClick={() => setShowPaymentType(true)}
                    condition={!isDepositActive}
                />
                {/* <DepositButton
                    title="Status"
                    handleClick={() => setShowStatus(true)}
                    condition={isManual}
                /> */}
                {/* <DepositButton
                    title="Cancel"
                    handleClick={() => setShowCancel(true)}
                    condition={depositPayment.canCancel}
                    variant="danger"
                /> */}
            </Box>

            {/* <PopupForm
                title="Change Status"
                action={handleStatus}
                show={showStatus}
                setShow={setShowStatus}
                inputs={[
                    [
                        {
                            id: 'status',
                            children: [
                                { id: 'Scheduled' },
                                { id: 'InProgress' },
                                { id: 'Completed' },
                                { id: 'Cancelled' },
                            ],
                        },
                    ],
                    [{ id: 'completedAt', date: true, optional: true }],
                ]}
                defaults={{
                    depositPaymentId: depositPayment.id,
                    status: manualPayment?.status || '',
                    completedAt: manualPayment?.completedAt || '',
                }}
            /> */}

            <PopupForm
                title="Change Payment Type"
                action={handleChangePaymentType}
                show={showPaymentType}
                setShow={setShowPaymentType}
                inputs={[
                    [
                        {
                            id: 'paymentTypeId',
                            source: getPaymentTypes,
                        },
                    ],
                ]}
                defaults={{
                    depositPaymentId: depositPayment.id,
                    paymentTypeId: paymentTypeId,
                }}
            />

            <PopupForm
                title="Cancel Deposit?"
                body="Are you sure you want to cancel this upcoming deposit?"
                action={handleCancel}
                show={showCancel}
                setShow={setShowCancel}
            />
        </Box>
    );
}

function DepositButton({ title, variant = 'white', handleClick, condition }) {
    if (!condition) {
        return null;
    }

    return (
        <Button
            size="tiny"
            variant={variant}
            onClick={handleClick}
            sx={{ marginTop: 1, marginRight: 1 }}
        >
            {title}
        </Button>
    );
}

function CommissionInfo({ data: pcp, props }) {
    return (
        <Box padding={1} marginTop={1} backgroundColor="#f4f4f4">
            <Line
                title="Event"
                value={pcp.commissionPayment?.eventType?.title}
                gap
            />
            <Line
                title="Amount"
                value={`${formatText(pcp.amount, formatTypes.MONEY)} (${
                    pcp.commissionPayment.percent !== null
                        ? `${pcp.commissionPayment.percent}%`
                        : 'Fixed'
                })`}
            />
            <Line title="Paid" value={pcp?.paid ? 'Yes' : 'No'} />
            <Line title="Paid Date" value={pcp?.paidDate} gap />
            <Line
                title="Expected Date"
                value={`${formatText(
                    pcp.event?.expectedDate,
                    formatTypes.DATE
                )}`}
            />
            <Line
                title="Scheduled Date"
                value={`${formatText(
                    pcp.event?.scheduleDate,
                    formatTypes.DATE
                )}`}
            />
            <Line
                title="Completed At"
                value={`${formatText(
                    pcp.event?.completedAt,
                    formatTypes.DATE
                )}`}
                gap
            />
            <Line title="PCP ID" value={formatText(pcp.id)} minor />
        </Box>
    );
}

function Section({ title, children }) {
    return (
        <Grid item xs={12} md={4} marginBottom={3}>
            <Typography fontSize={16} fontWeight="bold" gutterBottom>
                {title}
            </Typography>
            {children}
        </Grid>
    );
}

function Verifications({ purchase }) {
    if (!purchase) {
        return <Status title="Not Ready" value={false} />;
    }
    return purchase.signatures.map((signature, i) => {
        return (
            <Status
                key={signature.id}
                title={`${signature.firstName} ${signature.lastName} (${
                    signature.email
                })${signature.officer ? ' - Officer' : ''}`}
                value={signature.idvStatus === 'success'}
                tooltip={signature.idvStatus}
            />
        );
    });
}

function Status({ title, value, tooltip }) {
    return (
        <Box display="flex">
            <Tooltip title={tooltip}>
                <Box>
                    <DynamicIcon
                        icon={value ? 'CheckCircle' : 'Cancel'}
                        sx={{ marginRight: 1 }}
                        colour={value ? 'status.success' : 'status.danger'}
                    />
                </Box>
            </Tooltip>
            <Typography>{title}</Typography>
        </Box>
    );
}

function Payments({ deposits = [] }) {
    return deposits.map((deposit) => {
        const payment =
            deposit.depositPayment?.fund ||
            deposit.depositPayment?.manualPayment;

        return (
            <Box key={deposit.id}>
                <Status
                    title={`Deposit #${deposit.number}${
                        deposit.depositPayment?.dueDate
                            ? ` - ${moment(
                                  deposit.depositPayment?.dueDate
                              ).format('MMMM D, YYYY')}`
                            : ''
                    }`}
                    value={payment?.completedAt}
                />
            </Box>
        );
    });
}

export default PostPurchase;
