const amendmentTypes = {
    ADD_PURCHASER: { id: 1, title: 'Add Purchaser' },
    REMOVE_PURCHASER: { id: 2, title: 'Remove Purchaser' },
    CHANGE_OCCUPANCY_DECLARATION: {
        id: 3,
        title: 'Change Occupancy Declaration',
    },
    ADD_INCENTIVE: { id: 4, title: 'Add Incentive' },
    CHANGE_DEPOSIT_SCHEDULE: { id: 5, title: 'Change Deposit Structure' },
    EXTEND_COOLING: { id: 6, title: 'Extend Cooling' },
    CUSTOM: { id: 7, title: 'Custom' },
    MUTUAL_RELEASE: { id: 8, title: 'Mutual Release' },
    ADD_UPGRADE: { id: 9, title: 'Add Upgrade' },
};

export const amendmentTypesById = {
    1: 'Add Purchaser',
    2: 'Remove Purchaser',
    3: 'Change Occupancy Declaration',
    4: 'Add Incentive',
    5: 'Change Deposit Structure',
    6: 'Extend Cooling',
    7: 'Custom',
    8: 'Mutual Release',
    9: 'Add Upgrade',
};

export default amendmentTypes;
