const analytics = (window.analytics = window.analytics || []);

const identify = (uuid, traits) => {
    if (uuid) {
        analytics.identify(uuid, traits);
    } else {
        analytics.identify(traits);
    }
};

const track = (event, properties) => {
    analytics.track(event, properties);
};

export { identify, track };
